import axios from "axios";
import { DateTime } from "luxon";

async function fetchNotifications() {
    try {
        const { data } = await axios.get("/notifications");

        this.unreadNotificationsCount = data.unread_notifications_count;
        this.readNotificationsCount = data.read_notifications_count;
        this.notifications = data.notifications.data;

    } catch (e) {
        console.error(e);
    }
}

async function setNotificationRead(notification) {
    // Already read, no need.
    if (notification.read_at) {
        return;
    }

    try {
        const { data } = await axios.put(`/notifications/${notification.id}`, {
            read: true,
        });

        const index = this.notifications.findIndex(n => n.id === notification.id);
        this.notifications[index] = data;

    } catch (e) {
        console.error(e);
    }
}

async function handleNotificationClick(notification) {
    this.setNotificationRead(notification).then(r => {
    });

    if (notification.data.action && notification.data.action !== "#") {
        window.location.replace(notification.data.action);
    }
}

async function clearAllNotifications() {
    return axios.post('/notifications/clear').then(() => this.fetchNotifications);
}

function notificationData(userId) {
    return {
        userId,
        notifications: [],
        unreadNotificationsCount: null,
        readNotificationsCount: null,

        get formattedNotifications() {
            return this.notifications.map(function (notification) {
                return {
                    ...notification,
                    is_unread: notification.read_at === null,
                    is_read: notification.read_at !== null,
                    created_at: new DateTime(notification.created_at)
                        .setLocale("ar")
                        .toLocaleString(DateTime.DATE_FULL),
                };
            });
        },

        init() {
            this.fetchNotifications();

            // Let's refresh every 30 seconds
            setInterval(() => this.fetchNotifications(), 1000 * 30);
        },

        fetchNotifications,
        handleNotificationClick,
        setNotificationRead,
        clearAllNotifications,
    };
}

window.appNotificationsData = notificationData;
