import ClipboardJS from "clipboard";

document.addEventListener("DOMContentLoaded", function () {
    const elements = document.querySelectorAll(".clipboard");

    // Remove copy buttons, the feature is not supported.
    if (!ClipboardJS.isSupported()) {
        elements.forEach(el => el.parentElement.classList.add("d-none"));

        return;
    }

    const clipboard = new ClipboardJS(".clipboard");

    clipboard.on("success", function (e) {
        // TODO show a tooltip when text is copied.
        console.info("Action:", e.action);
        console.info("Text:", e.text);
        console.info("Trigger:", e.trigger);

        e.clearSelection();
    });

    clipboard.on("error", function (e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
    });

});